<template>
  <div class="sideBar">

    <div class="side-item-block">
      <div class="indexbtn whitefont index" style="" @click="toHome()">
        <i class="iconfont icon-a-zu30" />
        <span>首页</span>
      </div>
      <el-menu :default-active="defaultActive" :unique-opened="true" class="el-menu-vertical-demo" text-color="#595959"
        @open="handleOpen" @close="handleClose">
        <el-submenu v-for="item in sidebarItem" v-if="item.status" :index="item.parent_name">
          <template slot="title">
            <i class="iconfont" :class="item.icon" />
            <span>{{ item.title }}</span>
          </template>
          <el-menu-item v-for="sub in item.children" v-if="sub.status" :index="sub.name" @click="handleMenuItem(sub)">
            {{ sub.title }}</el-menu-item>
        </el-submenu>
      </el-menu>
    </div>
  </div>
</template>

<script>
let vm;
export default {
  name: 'sideBar',
  data() {
    return {
      sidebarItem: [
        /* {
           title:'社区管理',
           shortName:'SQGL',
           parentName:'CommunityManage',
           icon:'icon-a-zu31',
           children:[
             {
               title:'小区管理',
               shortName:'XQGL',
               name:"Area",
               parentName:'Area',
             }
           ]
         },
         {
           title:'用户管理',
           shortName:'YHGL',
           parentName:"UserManage",
           icon:'icon-a-ziyuan2',
           children:[
             {
               title:'社区用户',
               shortName:'SQYH',
               name:'User',
               parentName:'User',
               children:[
                 {
                   title:'用户信息',
                   shortName:'SQYH',
                   name:'UserInfo',
                   parentName:'User',
                 }
               ]
             }
           ]
         },
         {
           title:'党组织管理',
           shortName:'DZZGL',
           parentName:'OrganizationManage',
           icon:'icon-a-zu33',
           children:[
             {
               title:'党支部管理',
               shortName:'DZBGL',
               name:'Branch',
               parentName:'Branch'
             },
             {
               title:'党员管理',
               shortName:'DYGL',
               name:'PartyMember',
               parentName:'PartyMember',
               children:[
                 {
                   title:'查看党员',
                   shortName:'DYGL',
                   name:'PM_info',
                   parentName:'PartyMember',
                 }
               ],
             },
             {
               title:'引领值奖励',
               shortName:'YLZJL',
               name:'LeadValue',
               parentName:'LeadValue'
             },
           ]
         },
         {
           title:'居民管理',
           shortName:'JMGL',
           icon:'icon-a-zu34',
           parentName:'ResidentsManage',
           children:[
             {
               title:'居民认证',
               shortName:'JMRZ',
               name:'Residents',
               parentName:'Residents',
               children:[
                 {
                   title:'居民信息',
                   shortName:'JMRZ',
                   name:'ResidentsInfo',
                   parentName:'Residents',
                 }
               ]
             },
             {
               title:'积分奖励',
               shortName:'JFJL',
               name:'Reward',
               parentName:'Reward',
             }
           ]
         },
         {
           title:'机构管理',
           shortName:'JGGL',
           icon:'icon-dongfangshandian',
           parentName:'InstitutionManage',
           children:[
             {
               title:'社区机构',
               shortName:'SQJG',
               name:'Institution',
               parentName:'Institution',
               children:[
                 {
                   title:'创建机构',
                   shortName:'SQJG',
                   name:'Create',
                   parentName:'Create',
                 },
                 {
                   title:'机构详情',
                   shortName:'SQJG',
                   name:'InstitutionDetail',
                   parentName:'InstitutionDetail',
                   children:[
                     {
                       title:'机构信息',
                       shortName:'SQJG',
                       name:'InstInfo',
                       parentName:'InstitutionDetail',
                     }
                   ]
                 },
               ]
             },
             {
               title:'机构用户',
               name:'InstUser',
               shortName:'JGYH',
               parentName:'InstUser',
               children:[
                 {
                   title:'用户详情',
                   name:"InstUserDetail",
                   shortName:'JGYH',
                   parentName:'InstUser',
                 }
               ]
             },
             {
               title:'机构值明细',
               name:'InstData',
               shortName:'JGZMX',
               parentName:'InstData',
             }
           ]
         },
         {
           title:'任务管理',
           shortName:'RWGL',
           icon:'icon-huanjinggenghaojiu',
           parentName:'TaskManage',
           children:[
             {
               title:'任务列表',
               shortName:'FBRW',
               name:"TaskList",
               parentName:'TaskList',
               children:[
                 {
                   title:'任务详情',
                   shortName:'FBRW',
                   name:'TaskDetail',
                   parentName:'TaskList',
                   children:[
                     {
                       title:'任务审核详情',
                       shortName:'FBRW',
                       name:'AuditedList',
                       parentName:'TaskList',
                     },
                   ]
                 },
                 {
                   title:'发布任务',
                   shortName:'FBRW',
                   parentName:'TaskList',
                   name:'Release',
                 },
               ],
             },{
               title:'任务积分审核',
               shortName:'RWJFSH',
               name:"Audit",
               parentName:'Audit',
               children:[
                 {
                   title:'任务审核详情',
                   shortName:'RWJFSH',
                   name:'AuditDetail',
                   parentName:'AuditDetail',
                 }
               ]
             },
           ]
         },
         {
           title:'积分管理',
           shortName:'JFGL',
           icon:'icon-a-ziyuan1',
           parentName:'IntegralManage',
           children:[
             {
               title:'社区积分',
               shortName:'SQJF',
               name:'Integral',
               parentName:'Integral',
             },{
               title:'奖励流水',
               shortName:'JLLS',
               name:'IntegralDetail',
               parentName:'IntegralDetail',
             },
           ]
         },
         {
           title:'广告管理',
           shortName:'GGGL',
           icon:'icon-VBv',
           parentName:'AdManage',
           children:[
             {
               title:'首页主图',
               shortName:'SYZT',
               name:'HomeAd',
               parentName:'HomeAd',
             },
           ]
         },
         {
           title:'内容管理',
           shortName:'NRGL',
           icon:'icon-VBcongaide',
           parentName:'ContentManage',
           children:[
             {
               title:'图文管理',
               shortName:'TWGL',
               name:"Content",
               parentName:'Content'
             },
           ]
         },
         {
           title:'账号设置',
           shortName:'ZHSZ',
           icon:'icon-xiaochengzhichun',
           parentName:'AccountManage',
           children:[
             {
               title:'成员管理',
               shortName:'CYGL',
               name:'Member',
               parentName:'Member',
             },
             {
               title:'角色管理',
               shortName:'JSGL',
               name:'Role',
               parentName:'Role',
               children:[
                 {
                   title:'权限设置',
                   shortName:'JSGL',
                   name:'Permission',
                   parentName:'Role',
                 }
               ]
             },
           ]
         }*/
      ],
      defaultActive: this.$route.meta.parentName,
    }
  },
  computed: {
    sidebar() {
      return this.$store.state.user.sidebarItem;
    },

    active() {
      return this.$route.meta.parentName;
    },
  },
  watch: {
    sidebar(val, old) {
      vm.sidebarItem = val;
      vm.$store.commit('user/SET_SIDEBAR', vm.sidebarItem);
      vm.$forceUpdate();
    },

    active(val, old) {
      vm.defaultActive = val;
    }
  },
  mounted() {
    vm = this;
    vm.init();
  },
  methods: {
    handleOpen(key, keyPath) {

    },
    handleClose(key, keyPath) {

    },

    handleMenuItem(obj) {
      vm.$router.push({ name: obj.name });
      vm.$store.commit('tagsView/pushtags', obj)

    },

    init() {
      if (localStorage.getItem('village_sidebar')) {
        vm.sidebarItem = JSON.parse(localStorage.getItem('village_sidebar'))
      }
      vm.$store.commit('user/SET_SIDEBAR', vm.sidebarItem)
    },

    toHome() {
      vm.$router.push({ name: 'Home' })
      let obj = {
        name: "Home",
        shortName: "Home",
        title: "首页",
      }
      vm.$store.commit('tagsView/pushtags', obj)
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../styles/sidebar";

.indexbtn {
  width: 186px;
  height: 40px;
  line-height: 40px;
  /* background: #FFA54B; */
  color: rgb(89, 89, 89);
  border-radius: 6px;
  padding-left: 20px;
  cursor: pointer;
}
</style>



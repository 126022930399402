const api = {

  userinfo: '/user/detail',  //我的信息

  login: '/auth/login',  //登录

  material: '/user/material',    //修改密码

  destroy: '/user/destroy',    //删除用户

  memberTag: '/member/tag',   //用户打标签

  member: '/member',   //用户列表

  detail: '/member/show',  //用户详情

  apply: '/member/apply',  //身份审核

  bind: "/member/bind",  //绑定身份

  sq_list: '/area',    //社区列表

  del_area: '/area/delete',  //删除小区

  edit_area: '/area/update',   //编辑小区

  node: '/node',  //菜单（节点）列表

  del_node: "/node/delete",    //删除节点

  update_node: '/node/update',   //修改节点

  dzz_list: "/ccp/branch",   //党组织列表

  del_branch: "/ccp/branch/delete",    //删除党支部

  update_branch: "/ccp/branch/update",   //修改党支部

  party: '/ccp/party',   //党员列表

  party_show: '/ccp/party/show',   //党员详情

  update_party: '/ccp/party/update',   //修改党员

  del_party: '/ccp/party/delete',   //删除党员

  import_party: '/ccp/import',   //导入党员

  ad_list: '/ad',    //广告列表

  del_ad: '/ad/delete',  //删除广告

  update_ad: "/ad/update",   //修改广告

  add_ad: "/ad-brand",   //增加

  add_theme:"/theme", //增加背景标题
  get_theme:"/theme", //get背景标题  

  join_picture:"/join_picture", //

  upload: '/common/upload',    //上传附件

  task: '/task',   //任务列表
  task_notify: '/task_notify',   //任务列表

  task_show: '/task/show',   //任务详情

  task_confirm: '/task/confirm',   //确认活动完结

  update_task: '/task/update',   //修改任务

  del_task: '/task/delete',    //删除任务

  cancel_task: '/task/cancel',   //取消任务

  join: '/task/join',    //参与人员

  sign: '/task/sign',    //报名

  content: '/content',   //内容管理

  del_content: '/content/delete',  //删除内容

  update_content: '/content/update',  //删除内容

  stream: "/stream",   //积分流水

  account: '/account',   //账号列表

  del_account: '/account/delete',   //账号列表

  update_account: '/account/update',   //编辑账号

  role: '/role',   //角色

  del_role: '/role/delete',    //删除角色

  update_role: '/role/update',   //修改角色

  organ: '/organ',   //机构列表

  del_organ: '/organ/delete',  //删除机构

  organ_show: '/organ/show', //机构详情

  organuser: '/organuser',   //机构用户列表

  organuser_show: '/organuser/show',   //机构用户详情

  del_organuser: '/organuser/delete',    //删除机构用户

  bind_organ: '/organuser',    //绑定机构

  menu: '/menu',   //节点列表

  permission: '/permission',  //权限列表

  organ_list: '/organ/list',   //机构列表

  villages: '/villages',   //社区登录图片

  batchImport: '/member/batch-import-store', //批量导入

  statistics: 'members/statistics', //公众数据报表

  kanban: 'task/kanban',//任务数据报表

  GZtips: 'tags/group/list',//公众标签

  RWtips: 'tags/category',//任务标签

  category: 'tags/category',//标签

  addRWtags: 'tags',//添加任务标签

  addTagsG: 'tags/group/store',//添加标签分组
  getTagsGroupByType: 'tags/group/list',//列表
  getTagsByGroupId: 'tags/group/show-list',//列表
  delTagById: '/tags/group/batch',
  addTag: 'tags',//添加标签

  purchase: 'purchase',//采购商城

  purchaseCar: 'purchase/car',//采购商城购物车

  buy: 'purchase/order',//下单

  getCar: 'purchase/car',//获取购物车

  orders: 'purchase-orders',//获取订单
  cancelOrders: 'purchase-orders/cancel/',//获取订单

  recommend: 'products/recommend',//获取商城推荐

  specialness: 'products/specialness',//获取特价商品
  updateSpecialness: 'products/specialness-update',//修改特价商品

  products: 'products',//产品管理
  updateProducts: 'products/update',//修改商品管理
  batchStatusProducts: 'products/batch-status',//产品批量上下架
  batchDestoyProducts: 'products/batch-destroy',//产品批量删除

  appletHome: 'applet-home/store',//小程序首页

  appletHomeG: 'applet-home/show',//获取

  roleList: 'customer/role',//公众配置

  roleStaus: 'customer/role',//修改状态

  showRole: 'customer/role',//公众详情

  banding: 'member/binding',//核销绑定

  unbinding: 'unbinding',//取消绑定

  Getbind: '/binding',//获取绑定

  organsList: 'organs/list',//商城报表

  memberBindList: '/binding/get',//获取绑定

  posts: 'posts',//友领圈
  posts_no: 'posts/check',//友领圈 审核不通过

  thing_index: 'thing-index',//先进事迹图

  residentialType:'common/residential-type', //获取小区类型列表
  residentialList:'common/residential-list', //2.通过小区类型获取小区列表
};
export default api

import { getToken, setToken, removeToken } from '../../utils/auth'
import { login, getInfo } from '@/api/user'

const state = {
  token:'',
  userInfo:{},
  sidebarItem:'',
  taskDetail:'',
}

const getDefaultState = () => {
  return {
    token: getToken(),
    userInfo:'',
    sidebarItem:'',
    taskDetail:'',
  }
}


const mutations = {
  setTaskDetail(state,val){
    state.taskDetail = val
    sessionStorage.setItem('taskDetail',JSON.stringify(val))
  },
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  },

  SET_TOKEN: (state, token) => {
    state.token = token;
    setToken(token)
  },

  SET_USERINFO: (state, userInfo) => {
    state.userInfo = userInfo;
    localStorage.setItem('userinfo',JSON.stringify(userInfo))
  },

  DEL_USERINFO: (state)=>{
    state.userInfo = '';
    localStorage.removeItem('userinfo');
  },

  SET_SIDEBAR: (state, sidebar) => {
    state.sidebarItem = sidebar;
    localStorage.setItem('village_sidebar', JSON.stringify(state.sidebarItem))
  },

  DEL_SIDEBAR:(state)=>{
    state.sidebar = [];
    localStorage.removeItem('village_sidebar')
  }

}
const actions = {
  login({ commit }, userInfo) {
    const { username, aesPassword } = userInfo
    return new Promise((resolve, reject) => {
      login({ username: username.trim(), password: aesPassword }).then(response => {
        const { data } = response;
        commit('SET_TOKEN', data.token)
        commit('SET_USERINFO', data);
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo(state.token).then(response => {
        const { data } = response

        if (!data) {
          return reject('Verification failed, please Login again.')
        }
        commit('SET_USERINFO', data);
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      removeToken() // must remove  token  first
      commit('RESET_STATE')
      resolve()
    })
  }

}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}

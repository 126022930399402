// // 根据环境引入不同配置 process.env.VUE_APP_ENV
// // --开始使用
// const environment = process.env.VUE_APP_ENV || 'production'
// const config = require('./env.' + environment)
// let hostArray = "visitor.cbo1.jifenxitong.com".split('.')
// let hostsplit;
// if(hostArray.length === 4){
//   // hostsplit = hostArray[0];
//   config.baseUrl =  'http://applet1.jifenxitong.com/';
// }
// config.hostsplit = hostsplit;
// module.exports = config

//--测试或生产使用
const environment = process.env.VUE_APP_ENV || 'production'
const config = require('./env.' + environment)
let hostArray = window.location.host.split('.')
let hostsplit;
if(hostArray.length === 4){
  hostsplit = hostArray[0];
  config.baseUrl =  'http://'+hostsplit+'.cbo1.jifenxitong.com/';
}
// hostsplit = 'visitor';
config.hostsplit = hostsplit;
module.exports = config

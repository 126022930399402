<template>
    <div class="breadcrumb-block">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item
          v-for="(m,index) in matchedItem" :key="index"
          :to="m.name?{name:m.name,query:m.queryData}:''"
          :class="m.name===curr_route?'route-active':''">
          {{m.title}}
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
</template>

<script>
  let vm;
  export default {
    name: 'Breadcrumb',
    data(){
      return{
        matchedItem:[],
        curr_route:'',
        sidebarItem:[],
      }
    },
    props:{
      queryData:{
        type:Object,
        default:() => {},
      },
      routeIdx:{
        type:Number,
      }
    },
    mounted() {
      vm = this;
      vm.getSidebarItem();
    },
    methods:{

      getSidebarItem(){
        vm.matchedItem = vm.$route.matched;
        vm.curr_route = vm.$route.name;
        vm.sidebarItem = vm.$store.state.user.sidebarItem;
        let First = [];
        let Second = [];
        let Third = [];
        let Four = [];
        vm.sidebarItem.map(res=>{
          res.children.map(sec=>{
            if(sec.name === vm.curr_route){
              First.push(res);
              Second.push(sec)
            }else{
              if(sec.children){
                sec.children.map(third =>{
                  if(third.name === vm.curr_route){
                    First.push(res);
                    Second.push(sec);
                    Third.push(third);
                  }else{
                    if(third.children){
                      third.children.map(four=>{
                        if(four.name === vm.curr_route){
                          First.push(res);
                          Second.push(sec);
                          Third.push(third);
                          Four.push(four);
                        }
                      })
                    }
                  }
                })
              }
            }
          })
        })
        vm.matchedItem = First.concat(Second).concat(Third).concat(Four);
        let index = vm.routeIdx?vm.routeIdx:vm.matchedItem.length - 2;
        vm.matchedItem[index].queryData = vm.queryData;
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../../styles/variables";
.breadcrumb-block{
  padding: 28px 42px;
  border-bottom: 1px solid $line;
  .el-breadcrumb__item{
    position: relative;
    color: $gray32;
  }
  /deep/.el-breadcrumb__inner.is-link{
    color: #606266;
    font-weight: inherit;
  }
  /deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner{
    color: $orange;
    font-weight: bold;
  }
  .route-active{
    position: relative;
    &:after{
      content: '';
      width: 56px;
      height: 4px;
      background: #FFA54B;
      border-radius: 0;
      display: block;
      position: absolute;
      bottom: -28px;
    }
  }
}
</style>

<template>
  <div class="navbar">
    <div>
      <img src="../../assets/images/logo-new.png" class="logo" alt="">
      <span class="f16 whitefont name">
        {{sheqv_address}}
      </span>
    </div>

    <div class="tool">

      <span v-if="!login">
         <span class="mr16 ver-middle f14" ><router-link :to="{path:'/login'}">登录</router-link></span>
      </span>
      <el-popover
        placement="bottom"
        v-if="login"
        width="157"
        trigger="click">
        <span  v-if="login" class="curpor" style="display: inline-block " slot="reference">
          <el-avatar size="small" :src="userInfo.avatar_url || circleUrl" />
          <span class="f14 whitefont " style="vertical-align: middle">设置</span>
        </span>
        <div class="popover-block">
          <div>
            <div style="font-weight: 400;font-size: 14px;">{{userInfo.realname}}</div>
            <div class="grayfont-32 mt10">账号：{{userInfo.username}}</div>
          </div>
          <ul>
            <li @click="updatePassword()">
              <i class="iconfont icon-mimasuo"/> <span>修改密码</span>
            </li>
<!--            <li >-->
<!--              <i class="iconfont icon-shezhi"></i> <span>账号设置</span>-->
<!--            </li>-->
            <li @click="loginOut()">
              <i class="iconfont icon-tuichu"/> <span>退出登录</span>
            </li>
          </ul>
        </div>
      </el-popover>
    </div>
  </div>
</template>

<script>

import { getToken, removeToken } from '../../utils/auth'

export default {
  data() {
    return {
      circleUrl: require('../../assets/images/default-avatar.png'),
      userInfo: {},
      sheqv_address: '',
      login: false
    }
  },
  computed: {
    monitor() {
      return this.$store.state.user.userInfo
    }
  },
  watch: {
    monitor() {
      this.userInfo = this.$store.state.user.userInfo
      this.$forceUpdate()
    }
  },
  mounted() {
    this.getUserInfo()

    if (!getToken()) {
      this.login = false
      this.$router.push({ name: 'Login' })
    } else {
      this.login = true
    }
  },
  methods: {
    getUserInfo() {
      if (localStorage.getItem('userinfo')) {
        this.userInfo = JSON.parse(localStorage.getItem('userinfo'))
        this.sheqv_address = this.userInfo.villages.title
      }
    },

    loginOut() {
      removeToken()
      this.$store.commit('user/DEL_USERINFO')
      this.$store.commit('user/DEL_SIDEBAR')
      this.$store.commit('tagsView/delete_tagsView')
      this.$router.push('/login')
    },

    updatePassword() {
      this.$router.push({ name: 'UpdatePassword' })
    }
  }

}
</script>

<style lang="scss" scoped>
  @import "../../styles/variables";
  .navbar{
    width: 100%;
    height: 58px;
    background-color: #FFA54B;
    padding: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 1px 4px rgba(0, 21, 41, 0.12);
    .logo{
      width: 120px;
      height: 48px;
      object-fit: cover;
      margin-right: 15px;
      vertical-align: middle;
    }
    .name{
      border-left: 1px solid #ffffff;
      padding-left: 15px;
      vertical-align: middle;
      display: inline-block;
    }
    .iconfont{
      color: #595959;
      font-size: 16px;
      margin-right: 26px;
      vertical-align: middle;
    }
    .avtar{

    }
  }
  .popover-block{
    ul{
      border-top:1px solid $line;
      margin-top: 12px;
      padding: 10px 0 0;
      li{
        padding: 7px 5px;
        cursor: pointer;
        .iconfont{
          font-size: 14px;
          color: #ACACAC;
          margin-right: 12px;
        }
      }
    }
  }
  /deep/.el-avatar--small{
    width: 24px;
    height: 24px;
    margin-right: 8px;
    vertical-align: middle;
  }
  /deep/.el-popover{
    padding: 16px!important;
  }
</style>

<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: 'App',
}
</script>
<style lang="scss">
@import "styles/variables";

[v-cloak] {
  display: none;
}

.index-container {
  background-color: #FFFFFF;
  // border-radius: 12px;
  border-radius: 4px;
  font-size: 14px;

  * {
    box-sizing: border-box;
  }
}

.el-date-picker.yingye-long-date {
  .el-date-picker__editor-wrap {
    &:last-child {
      display: none;
    }
  }
}

.el-date-range-picker.yingyeDate {
  .el-date-range-picker__time-picker-wrap {
    width: 66%;

    &:last-child {
      display: none;
    }

  }
}
</style>


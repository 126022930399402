<template>
    <div class="app-wrapper">
      <div class="fixed-header">
        <navbar />
      </div>
      <div class="main-container">
        <sidebar />
        <app-main />
      </div>

    </div>
</template>

<script>
  import { Navbar, AppMain,Sidebar } from './components'
  export default {
    name: 'Layout',
    components: {
      Navbar,
      AppMain,
      Sidebar
    },
  }
</script>

<style scoped lang="scss">
  *{
    box-sizing: border-box;
  }
  .app-wrapper{
    width: 100%;
    height: 100%;
    background-color: #F0F2F5;
  }
  .fixed-header {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9;
    width: 100%;
    transition: width 0.28s;
  }
  .main-container{
    width: 100%;
    padding:74px 24px 0;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: start;
    justify-content: space-between;
  }
</style>

import api from './index'
// axios
import request from '@/utils/request'

export function login(data) {
  return request({
    url: api.login,
    method: 'post',
    data
  })
}

export function getInfo(params) {
  return request({
    url: api.userinfo,
    method: 'get',
    params
  })
}

export function category(data) {
  return request({
    url: api.category,
    method: 'post',
    data
  })
}

export function delUser(data) {
  return request({
    url: api.destroy,
    method: 'post',
    data
  })
}

export function addTagsG(data) {
  return request({
    url: api.addTagsG,
    method: 'post',
    data
  })
}
export function getTagsGroupByType(params) {
  return request({
    url: api.getTagsGroupByType,
    method: 'get',
    params
  })
}
export function getTagsByGroupId(id) {
  return request({
    url: api.getTagsByGroupId+'/'+id,
    method: 'get'
  })
}
export function delTagById(params) {
  return request({
    url: api.delTagById+'/',
    method: 'DELETE',
    params
  })
}

export function addTag(data) {
  return request({
    url: api.addTag,
    method: 'post',
    data
  })
}

export function addRWtags(data) {
  return request({
    url: api.addRWtags,
    method: 'post',
    data
  })
}

export function getMember(params) {
  return request({
    url: api.member,
    method: 'get',
    params
  })
}

export function statistics(params) {
  return request({
    url: api.statistics,
    method: 'get',
    params
  })
}

export function kanban(params) {
  return request({
    url: api.kanban,
    method: 'get',
    params
  })
}

export function getDetail(params) {
  return request({
    url: api.detail,
    method: 'get',
    params
  })
}

export function setApply(data) {
  return request({
    url: api.apply,
    method: 'post',
    data
  })
}

export function batchImport(data) {
  return request({
    url: api.batchImport,
    method: 'post',
    data
  })
}

export function purchase(params) {
  return request({
    url: api.purchase,
    method: 'get',
    params
  })
}

export function purchaseDel(params) {
  return request({
    url: api.purchaseCar + params,
    method: 'delete',

  })
}

export function getCar(params) {
  return request({
    url: api.getCar,
    method: 'get',
    params
  })
}

export function recommend(params) {
  return request({
    url: api.recommend,
    method: 'get',
    params
  })
}

export function recommendAdd(data) {
  return request({
    url: api.recommend,
    method: 'post',
    data
  })
}

export function appletHome(data) {
  return request({
    url: api.appletHome,
    method: 'post',
    data
  })
}
export function batchStatusProducts(data) {
  return request({
    url: api.batchStatusProducts,
    method: 'post',
    data
  })
}
export function updateProducts(data) {
  return request({
    url: api.updateProducts,
    method: 'post',
    data
  })
}

export function batchDestoyProducts(data) {
  return request({
    url: api.batchDestoyProducts,
    method: 'post',
    data
  })
}

export function orders(params) {
  return request({
    url: api.orders,
    method: 'get',
    params
  })
}

export function appletHomeG(params) {
  return request({
    url: api.appletHomeG,
    method: 'get',
    params
  })
}

export function products(params) {
  return request({
    url: api.products,
    method: 'get',
    params
  })
}
export function productsPut(params, id) {
  return request({
    url: api.products + '/' + id,
    method: 'put',
    params
  })
}

export function showRole(params, id) {
  return request({
    url: api.showRole + '/' + id,
    method: 'get',
    params
  })
}

export function roleList(params) {
  return request({
    url: api.roleList,
    method: 'get',
    params
  })
}

export function roleAdd(data) {
  return request({
    url: api.roleList,
    method: 'post',
    data
  })
}

export function roleDel(id) {
  return request({
    url: api.roleList + '/' + id,
    method: 'delete',
  })
}

export function cancelOrders(id) {
  return request({
    url: api.cancelOrders + id,
    method: 'put',
  })
}

export function roleStaus(params, id) {
  return request({
    url: api.roleStaus + '/' + id,
    method: 'put',
    params
  })
}

export function addUser(data) {
  return request({
    url: api.member,
    method: 'post',
    data
  })
}

export function buy(data, id) {
  return request({
    url: api.buy + '/' + id,
    method: 'post',
    data
  })
}
export function singleBuy(id) {
  return request({
    url: api.buy + '/' + id,
    method: 'post'
  })
}

export function purchaseCar(data) {
  return request({
    url: api.purchaseCar,
    method: 'post',
    data
  })
}

export function bindType(data) {
  return request({
    url: api.bind,
    method: 'post',
    data
  })
}







//社区
export function sq_list(params) {
  return request({
    url: api.sq_list,
    method: 'get',
    params
  })
}

export function add_area(data) {
  return request({
    url: api.sq_list,
    method: 'post',
    data
  })
}

export function del_area(data) {
  return request({
    url: api.del_area,
    method: 'post',
    data
  })
}

export function edit_area(data) {
  return request({
    url: api.edit_area,
    method: 'post',
    data
  })
}


//节点管理
export function getNode(params) {
  return request({
    url: api.node,
    method: 'get',
    params
  })
}

export function del_node(data) {
  return request({
    url: api.del_node,
    method: 'post',
    data
  })
}

export function update_node(data) {
  return request({
    url: api.update_node,
    method: 'post',
    data
  })
}

export function add_node(data) {
  return request({
    url: api.node,
    method: 'post',
    data
  })
}

export function binding(params) {
  return request({
    url: api.banding,
    method: 'get',
    params
  })
}

export function posts(params) {
  return request({
    url: api.posts,
    method: 'get',
    params
  })
}

export function PaSSposts(data) {
  return request({
    url: api.posts,
    method: 'put',
    data
  })
}
export function postsNo(params) {
  return request({
    url: api.posts_no,
    method: 'get',
    params
  })
}

export function banding(data) {
  return request({
    url: api.banding,
    method: 'post',
    data
  })
}

export function unbinding(data) {
  return request({
    url: api.unbinding,
    method: 'post',
    data
  })
}


//党支部
export function dzz_list(params) {
  return request({
    url: api.dzz_list,
    method: 'get',
    params
  })
}

export function GZtips(params) {
  return request({
    url: api.GZtips,
    method: 'get',
    params
  })
}

export function RWtips(params) {
  return request({
    url: api.RWtips,
    method: 'get',
    params
  })
}

export function organsList(params) {
  return request({
    url: api.organsList,
    method: 'get',
    params
  })
}
export function memberBindList(params) {
  return request({
    url: api.memberBindList,
    method: 'get',
    params
  })
}

export function add_banch(data) {
  return request({
    url: api.dzz_list,
    method: 'post',
    data
  })
}
export function memberTag(data) {
  return request({
    url: api.memberTag,
    method: 'post',
    data
  })
}

export function del_banch(data) {
  return request({
    url: api.del_branch,
    method: 'post',
    data
  })
}

export function update_banch(data) {
  return request({
    url: api.update_branch,
    method: 'post',
    data
  })
}

//广告
export function ad_list(params) {
  return request({
    url: api.ad_list,
    method: 'get',
    params
  })
}

export function Getbind(params) {
  return request({
    url: api.Getbind,
    method: 'get',
    params
  })
}

export function thing_index(params) {
  return request({
    url: api.thing_index,
    method: 'get',
    params
  })
}

export function GetSpecia(params) {
  return request({
    url: api.specialness,
    method: 'get',
    params
  })
}
export function delSpecia(id) {
  return request({
    url: api.specialness+'/'+id,
    method: 'DELETE',
  })
}

export function specialness(data) {
  return request({
    url: api.specialness,
    method: 'post',
    data
  })
}
export function updateSpecialness(data) {
  return request({
    url: api.updateSpecialness,
    method: 'post',
    data
  })
}

export function add_AD(data) {
  return request({
    url: api.ad_list,
    method: 'POST',
    data
  })
}

export function upload(data) {
  return request({
    url: api.upload,
    method: 'post',
    timeout: 0,
    data
  })
}


export function del_AD(data) {
  return request({
    url: api.del_ad,
    method: 'POST',
    data
  })
}


export function update_AD(data) {
  return request({
    url: api.update_ad,
    method: 'POST',
    data
  })
}

export function getBrandAD(params) {
  return request({
    url: api.add_ad,
    method: 'get',
    params
  })
}
export function addBrandAD(data) {
  return request({
    url: api.add_ad,
    method: 'POST',
    data
  })
}
export function task_notify(data) {
  return request({
    url: api.task_notify,
    method: 'POST',
    data
  })
}
export function get_task_notify(params) {
  return request({
    url: api.task_notify,
    method: 'get',
    params
  })
}

//任务
export function get_task(params) {
  return request({
    url: api.task,
    method: 'get',
    params
  })
}

export function task_show(params) {
  return request({
    url: api.task_show,
    method: 'get',
    params
  })
}

export function join(params) {
  return request({
    url: api.join,
    method: 'get',
    params
  })
}

export function sign(data) {
  return request({
    url: api.sign,
    method: 'post',
    data
  })
}

export function cancel_task(data) {
  return request({
    url: api.cancel_task,
    method: 'post',
    data
  })
}

export function set_task(data) {
  return request({
    url: api.task,
    method: 'post',
    data
  })
}

export function del_task(data) {
  return request({
    url: api.del_task,
    method: 'post',
    data
  })
}

export function update_task(data) {
  return request({
    url: api.update_task,
    method: 'post',
    data
  })
}

export function task_confirm(data) {
  return request({
    url: api.task_confirm,
    method: 'post',
    data
  })
}



//新建内容
export function content(params) {
  return request({
    url: api.content,
    method: 'get',
    params
  })
}

export function add_content(data) {
  return request({
    url: api.content,
    method: 'post',
    data
  })
}

export function del_content(data) {
  return request({
    url: api.del_content,
    method: 'post',
    data
  })
}

export function update_content(data) {
  return request({
    url: api.update_content,
    method: 'post',
    data
  })
}
export function add_theme(data) {
  return request({
    url: api.add_theme,
    method: 'post',
    data
  })
}
export function get_theme(params) {
  return request({
    url: api.get_theme,
    method: 'get',
    params
  })
}
export function add_join_picture(data) {
  return request({
    url: api.join_picture,
    method: 'post',
    data
  })
}
export function get_join_picture(params) {
  return request({
    url: api.join_picture,
    method: 'get',
    params
  })
}
//积分
export function stream(params) {
  return request({
    url: api.stream,
    method: 'get',
    params
  })
}

export function set_stream(data) {
  return request({
    url: api.stream,
    method: 'post',
    data
  })
}


//账号管理
export function account(params) {
  return request({
    url: api.account,
    method: 'get',
    params
  })
}

export function add_account(data) {
  return request({
    url: api.account,
    method: 'post',
    data
  })
}

export function del_account(data) {
  return request({
    url: api.del_account,
    method: 'post',
    data
  })
}

export function update_account(data) {
  return request({
    url: api.update_account,
    method: 'post',
    data
  })
}

//角色
export function role(params) {
  return request({
    url: api.role,
    method: 'get',
    params
  })
}

export function add_role(data) {
  return request({
    url: api.role,
    method: 'post',
    data
  })
}

export function del_role(data) {
  return request({
    url: api.del_role,
    method: 'post',
    data
  })
}

export function update_role(data) {
  return request({
    url: api.update_role,
    method: 'post',
    data
  })
}

//机构

export function organ(params) {
  return request({
    url: api.organ,
    method: 'get',
    params
  })
}

export function add_organ(data) {
  return request({
    url: api.organ,
    method: 'post',
    data
  })
}

export function del_organ(data) {
  return request({
    url: api.del_organ,
    method: 'post',
    data
  })
}

export function organ_show(params) {
  return request({
    url: api.organ_show,
    method: 'get',
    params
  })
}

export function organ_user(params) {
  return request({
    url: api.organuser,
    method: 'get',
    params
  })
}

export function organ_user_show(params) {
  return request({
    url: api.organuser_show,
    method: 'get',
    params
  })
}

export function del_organ_user(data) {
  return request({
    url: api.del_organuser,
    method: 'post',
    data
  })
}

export function bind_organ(data) {
  return request({
    url: api.bind_organ,
    method: 'post',
    data
  })
}



//党员
export function party(params) {
  return request({
    url: api.party,
    method: 'get',
    params
  })
}

export function party_show(params) {
  return request({
    url: api.party_show,
    method: 'get',
    params
  })
}

export function del_party(data) {
  return request({
    url: api.del_party,
    method: 'post',
    data
  })
}

export function update_party(data) {
  return request({
    url: api.update_party,
    method: 'post',
    data
  })
}

export function import_party(data) {
  return request({
    url: api.import_party,
    method: 'post',
    data
  })
}

export function material(data) {
  return request({
    url: api.material,
    method: 'post',
    data
  })
}


//节点权限
export function menu(params) {
  return request({
    url: api.menu,
    method: 'get',
    params
  })
}

export function permission(params) {
  return request({
    url: api.permission,
    method: 'get',
    params
  })
}

export function set_permission(data) {
  return request({
    url: api.permission,
    method: 'post',
    data
  })
}

export function organ_list(data) {
  return request({
    url: api.organ_list,
    method: 'post',
    data
  })
}


export function villages(params) {
  return request({
    url: api.villages,
    method: 'get',
    params
  })
}

export function getNewTheme(params) {
  return request({
    url: api.get_theme,
    method: 'get',
    params
  })
}

export function residentialType() {
  return request({
    url: api.residentialType,
    method: 'get',
  })
}

export function residentialList(params) {
  return request({
    url: api.residentialList,
    method: 'get',
    params
  })
}

/**
 * 基础路由
 * @type { *[] }
 */
import Layout from '@/layout'

export const constantRouterMap = [

  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login')
  },

  {
    path: '/',
    component: Layout,
    redirect: '/index',
    meta: {
      title: '首页',
      keepAlive: false
    },
    children: [
      {
        path: '/',
        name: 'Home',
        component: () => import('@/views/home/index'),
        meta: { title: '首页', keepAlive: false, shortName: 'Home' }
      }
    ]
  },

  {
    path: '/area',
    component: Layout,
    meta: { title: '社区管理', parentName: 'CommunityManage' },
    children: [
      {
        path: '/area',
        name: 'Area',
        component: () => import('@/views/area/index'),
        meta: { title: '小区管理', keepAlive: false, shortName: 'XQGL', parentName: 'Area' }
      }
    ]
  },

  {
    path: '/organization',
    component: Layout,
    meta: { title: '党组织管理', parentName: 'OrganizationManage' },
    children: [
      {
        path: '/branch',
        name: 'Branch',
        component: () => import('@/views/organization/branch'),
        meta: { title: '党支部管理', keepAlive: false, shortName: 'DZBGL', parentName: 'Branch' }
      },
      {
        path: '/partyMember',
        name: 'PartyMember',
        component: () => import('@/views/organization/partyMember'),
        meta: { title: '党员管理', keepAlive: false, shortName: 'DYGL', parentName: 'PartyMember' }
      },
      {
        path: '/leadValue',
        name: 'LeadValue',
        component: () => import('@/views/organization/leadValue'),
        meta: { title: '引领值奖励', keepAlive: false, shortName: 'YLZJL', parentName: 'LeadValue' }
      },
      {
        path: '/PM_info',
        name: 'PM_info',
        component: () => import('@/views/organization/PM_info'),
        meta: { title: '查看党员', keepAlive: false, shortName: 'DYGL', parentName: 'PartyMember' }
      }
    ]
  },

  // {
  //   path: '/public',
  //   component: Layout,
  //   meta: { title: '公众管理', parentName: 'PublicManage' },
  //   children: [
  //     {
  //       path: '/partyManage',
  //       name: 'PartyManage',
  //       component: () => import('@/views/publicmanage/index'),
  //       meta: { title: '党员管理', keepAlive: false, shortName: 'DYGL', parentName: 'PartyManage' }
  //     },
  //     {
  //       path: '/info',
  //       name: 'ResidentsInfo',
  //       component: () => import('@/views/residents/info'),
  //       meta: { title: '居民信息', keepAlive: false, shortName: 'JMRZ', parentName: 'Residents' }
  //     }, {
  //       path: '/roleList',
  //       name: 'RoleList',
  //       component: () => import('@/views/publicmanage/roleList'),
  //       meta: { title: '角色管理', keepAlive: false, shortName: 'JSGL', parentName: 'RoleList' }
  //     }
  //   ]
  // },
  {
    path: '/publicInfo',
    component: Layout,
    meta: { title: '公众信息管理', parentName: 'Pinfomation' },
    children: [
      {
        path: '/pubInfoManage',
        name: 'PubInfoManage',
        component: () => import('@/views/pubinfomanage/publicInfoIndex'),
        meta: { title: '公众管理', keepAlive: false, shortName: 'GZGL', parentName: 'PubInfoManage' }
      },
      {
        path: '/info',
        name: 'ResidentsInfo',
        component: () => import('@/views/residents/info'),
        meta: { title: '居民信息', keepAlive: false, shortName: 'JMRZ', parentName: 'Residents' }
      }, {
        path: '/reward',
        name: 'Reward',
        component: () => import('@/views/residents/reward'),
        meta: { title: '积分奖励', keepAlive: false, shortName: 'JFJL', parentName: 'Reward' }
      }
    ]
  },
  {
    path: '/declaration',
    component: Layout,
    meta: { title: '申报管理', parentName: 'Declaration' },
    children: [
      {
        path: '/declaration',
        name: 'DeclaratioIndex',
        component: () => import('@/views/declaration/declarationIndex'),
        meta: { title: '申报列表', keepAlive: false, shortName: 'SBLB', parentName: 'DeclaratioIndex' }
      },
      {
        path: '/declarationPeople',
        name: 'DeclarationPeople',
        component: () => import('@/views/declaration/declarationPeople'),
        meta: { title: '人工申报', keepAlive: false, shortName: 'RGSB', parentName: 'DeclarationPeople' }
      },
      {
        path: '/conditionType',
        name: 'ConditionType',
        component: () => import('@/views/declaration/condition/conditionType'),
        meta: { title: '申报条件分类', keepAlive: false, shortName: 'SBTJLB', parentName: 'ConditionType' }
      },
      {
        path: '/conditionIndex',
        name: 'ConditionIndex',
        component: () => import('@/views/declaration/condition/conditionIndex'),
        meta: { title: '申报条件列表', keepAlive: false, shortName: 'SBTJLB', parentName: 'ConditionIndex' }
      }, {
        path: '/declarationAudit',
        name: 'DeclarationAudit',
        component: () => import('@/views/declaration/process/index'),
        meta: { title: '申报审核', keepAlive: false, shortName: 'SBSH', parentName: 'DeclarationAudit' }
      }
    ]
  },
  {
    path: '/residents',
    component: Layout,
    meta: { title: '居民管理', parentName: 'ResidentsManage' },
    children: [
      {
        path: '/residents',
        name: 'Residents',
        component: () => import('@/views/residents/index'),
        meta: { title: '居民认证', keepAlive: false, shortName: 'JMRZ', parentName: 'Residents' }
      },
      {
        path: '/info',
        name: 'ResidentsInfo',
        component: () => import('@/views/residents/info'),
        meta: { title: '居民信息', keepAlive: false, shortName: 'JMRZ', parentName: 'Residents' }
      }, {
        path: '/reward',
        name: 'Reward',
        component: () => import('@/views/residents/reward'),
        meta: { title: '积分奖励', keepAlive: false, shortName: 'JFJL', parentName: 'Reward' }
      }
    ]
  },

  {
    path: '/user',
    component: Layout,
    meta: { title: '用户管理', parentName: 'UserManage' },
    children: [
      {
        path: '/user',
        name: 'User',
        component: () => import('@/views/user/index'),
        meta: { title: '社区用户', keepAlive: false, shortName: 'SQYH', parentName: 'User' }
      }, {
        path: '/userInfo',
        name: 'UserInfo',
        component: () => import('@/views/user/userInfo'),
        meta: { title: '用户信息', keepAlive: false, shortName: 'SQYH', parentName: 'User' }
      }
    ]
  },

  {
    path: '/task',
    component: Layout,
    meta: { title: '任务管理', parentName: 'TaskManage' },
    children: [
      {
        path: '/taskList',
        name: 'TaskList',
        component: () => import('@/views/task/index'),
        meta: { title: '任务列表', keepAlive: false, parentName: 'TaskList', shortName: 'FBRW' }
      }, {
        path: '/joinPeople',
        name: 'JoinPeople',
        component: () => import('@/views/task/joinPeople'),
        meta: { title: '参与人数', keepAlive: false, parentName: 'TaskList', shortName: 'CYRS' }
      }, {
        path: '/taskDetail',
        name: 'TaskDetail',
        component: () => import('@/views/task/detail'),
        meta: { title: '任务详情', keepAlive: false, parentName: 'TaskList', shortName: 'FBRW' }
      }, {
        path: '/taskPreview',
        name: 'TaskPreview',
        component: () => import('@/views/task/taskPreview'),
        meta: { title: '任务预览', keepAlive: false, parentName: 'TaskList', shortName: 'FBRW' }
      }, {
        path: '/auditedList',
        name: 'AuditedList',
        component: () => import('@/views/task/auditedList'),
        meta: { title: '任务审核列表', keepAlive: false, parentName: 'TaskList', shortName: 'FBRW' }
      }, {
        path: '/release',
        name: 'Release',
        component: () => import('@/views/task/release'),
        meta: { title: '发布任务', keepAlive: true, parentName: 'TaskList', shortName: 'FBRW' }
      }, {
        path: '/audit',
        name: 'Audit',
        component: () => import('@/views/task/audit'),
        meta: { title: '任务积分审核', keepAlive: false, parentName: 'Audit', shortName: 'RWJFSH' }
      }, {
        path: '/auditDetail',
        name: 'AuditDetail',
        component: () => import('@/views/task/auditDetail'),
        meta: { title: '任务审核详情', keepAlive: false, parentName: 'Audit', shortName: 'RWJFSH' }
      }, {
        path: '/TDataForm',
        name: 'TDataForm',
        component: () => import('@/views/task/TDataForm'),
        meta: { title: '任务数据报表', keepAlive: false, parentName: 'TDataForm', shortName: 'SJBB' }
      }, {
        path: '/taskJianbao',
        name: 'taskJianbao',
        component: () => import('@/views/task/taskJianbao'),
        meta: { title: '任务简报', keepAlive: false, parentName: 'TaskList', shortName: 'RWJB' }
      }
    ]
  },

  {
    path: '/integral',
    component: Layout,
    meta: { title: '积分管理', parentName: 'IntegralManage' },
    children: [
      {
        path: '/integral',
        name: 'Integral',
        component: () => import('@/views/integral/index'),
        meta: { title: '社区积分', keepAlive: false, parentName: 'Integral', shortName: 'SQJF' }
      },
      {
        path: '/integralDetail',
        name: 'IntegralDetail',
        component: () => import('@/views/integral/detail'),
        meta: { title: '奖励流水', keepAlive: false, parentName: 'IntegralDetail', shortName: 'JLLS' }
      },
      {
        path: '/familyIntegral',
        name: 'FamilyIntegral',
        component: () => import('@/views/integral/familyIntegral'),
        meta: { title: '家庭积分', keepAlive: false, parentName: 'FamilyIntegral', shortName: 'JTJF' }
      },
      {
        path: '/familyDetails',
        name: 'FamilyDetails',
        component: () => import('@/views/integral/familyDetails'),
        meta: { title: '家庭详情', keepAlive: false, parentName: 'FamilyIntegral', shortName: 'JTJF' }
      },
         {
        path: '/familyJFDetail',
        name: 'FamilyJFDetail',
        component: () => import('@/views/integral/familyJFDetail'),
        meta: { title: '积分明细', keepAlive: false, parentName: 'FamilyIntegral', shortName: 'JTJF' }
      },
      {
        path: '/incentive',
        name: 'Incentive',
        component: () => import('@/views/integral/incentive'),
        meta: { title: '激励积分', keepAlive: false, parentName: 'Incentive', shortName: 'JLJF' }
      },
      {
        path: '/ReAndPe',
        name: 'ReAndPe',
        component: () => import('@/views/integral/ReAndPe'),
        meta: { title: '积分奖罚', keepAlive: false, parentName: 'ReAndPe', shortName: 'JFJF' }
      }
    ]
  },

  {
    path: '/AD',
    component: Layout,
    meta: { title: '个性化配置', parentName: 'AdManage' },
    children: [
      {
        path: '/appletHome',
        name: 'AppletHome',
        component: () => import('@/views/AD/appletHome'),
        meta: { title: '小程序配置', keepAlive: false, parentName: 'AppletHome', shortName: 'XCXSY' }
      },
      {
        path: '/homeAd',
        name: 'HomeAd',
        component: () => import('@/views/AD/index'),
        meta: { title: '首页主图', keepAlive: false, parentName: 'HomeAd', shortName: 'SYZT' }
      },
      {
        path: '/navClassify',
        name: 'NavClassify',
        component: () => import('@/views/AD/navClassify'),
        meta: { title: '导航分类配置', keepAlive: false, parentName: 'NavClassify', shortName: 'DHFLPZ' }
      },
      {
        path: '/taskClassify',
        name: 'TaskClassify',
        component: () => import('@/views/AD/taskClassify'),
        meta: { title: '任务分类配置', keepAlive: false, parentName: 'TaskClassify', shortName: 'RWFLPZ' }
      },
      {
        path: '/notice',
        name: 'Notice',
        component: () => import('@/views/AD/notice'),
        meta: { title: '通知模块', keepAlive: false, parentName: 'Notice', shortName: 'TZMK' }
      },
      {
        path: '/home',
        name: 'HomeBG',
        component: () => import('@/views/AD/home'),
        meta: { title: '首页背景设置', keepAlive: false, parentName: 'HomeBG', shortName: 'SY' }
      },
      {
        path: '/deeds',
        name: 'Deeds',
        component: () => import('@/views/AD/deeds'),
        meta: { title: '先进事迹', keepAlive: false, parentName: 'Deeds', shortName: 'XJSJ' }
      },
      {
        path: '/settlein',
        name: 'settlein',
        component: () => import('@/views/AD/settlein'),
        meta: { title: '小程序入驻', keepAlive: false, parentName: 'settlein', shortName: 'XCXRZ' }
      },
      {
        path: '/admin',
        name: 'admin',
        component: () => import('@/views/AD/admin'),
        meta: { title: '后台配置', keepAlive: false, parentName: 'admin', shortName: 'HTPZ' }
      },
      {
        path: '/ALogin',
        name: 'ALogin',
        component: () => import('@/views/AD/ALogin'),
        meta: { title: '后台登陆图', keepAlive: false, parentName: 'ALogin', shortName: 'HTDL' }
      }
    ]
  },

  {
    path: '/institution',
    component: Layout,
    meta: { title: '机构管理', parentName: 'InstitutionManage' },
    children: [
      {
        path: '/institution',
        name: 'Institution',
        component: () => import('@/views/institution/index'),
        meta: { title: '社区机构', keepAlive: false, shortName: 'SQJG', parentName: 'Institution' }
      },
      {
        path: '/create',
        name: 'Create',
        component: () => import('@/views/institution/create'),
        meta: { title: '创建机构', keepAlive: true, parentName: 'Institution', shortName: 'SQJG' }
      },
      {
        path: '/detail',
        name: 'InstitutionDetail',
        component: () => import('@/views/institution/detail'),
        meta: { title: '机构详情', keepAlive: false, parentName: 'Institution', shortName: 'SQJG' }
      },
      {
        path: '/instInfo',
        name: 'InstInfo',
        component: () => import('@/views/institution/instInfo'),
        meta: { title: '机构信息', keepAlive: false, parentName: 'Institution', shortName: 'SQJG' }
      },
      {
        path: '/instUser',
        name: 'InstUser',
        component: () => import('@/views/institution/user'),
        meta: { title: '机构用户', keepAlive: false, parentName: 'InstUser', shortName: 'JGYH' }
      },
      {
        path: '/userDetail',
        name: 'InstUserDetail',
        component: () => import('@/views/institution/userDetail'),
        meta: { title: '用户详情', keepAlive: false, parentName: 'InstUser', shortName: 'JGYH' }
      },
      {
        path: '/instData',
        name: 'InstData',
        component: () => import('@/views/institution/instData'),
        meta: { title: '机构值明细', keepAlive: false, parentName: 'InstData', shortName: 'JGZMX' }
      }
    ]
  },

  {
    path: '/content',
    component: Layout,
    meta: { title: '内容管理', parentName: 'ContentManage' },
    children: [
      {
        path: '/content',
        name: 'Content',
        component: () => import('@/views/content/index'),
        meta: { title: '图文管理', keepAlive: false, parentName: 'Content', shortName: 'TWGL' }
      },
      {
        path: '/contentEdit',
        name: 'ContentEdit',
        component: () => import('@/views/content/contentEdit'),
        meta: { title: '添加图文', keepAlive: false, parentName: 'Content', shortName: 'TWTJ' }
      }
    ]
  },

  {
    path: '/account',
    component: Layout,
    meta: { title: '账号设置', parentName: 'AccountManage' },
    children: [
      {
        path: '/member',
        name: 'Member',
        component: () => import('@/views/account/member'),
        meta: { title: '成员管理', keepAlive: false, parentName: 'Member', shortName: 'CYGL' }
      },
      {
        path: '/role',
        name: 'Role',
        component: () => import('@/views/account/role'),
        meta: { title: '角色管理', keepAlive: false, parentName: 'Role', shortName: 'JSGL' }
      },
      {
        path: '/permission',
        name: 'Permission',
        component: () => import('@/views/account/permission'),
        meta: { title: '权限设置', keepAlive: false, parentName: 'Role', shortName: 'JSGL' }
      },
      {
        path: '/updatePassword',
        name: 'UpdatePassword',
        component: () => import('@/views/account/updatePassword'),
        meta: { title: '修改密码', keepAlive: false, parentName: 'UpdatePassword', shortName: 'XGMM' }
      }
    ]
  },
  // 新增加
  {
    path: '/publicManage',
    component: Layout,
    meta: { title: '公众管理', parentName: 'publicManage' },
    children: [
      {
        path: '/roleList',
        name: 'roleList',
        component: () => import('@/views/publicmanage/roleList'),
        meta: { title: '角色列表', keepAlive: false, parentName: 'roleList', shortName: 'JSLB' },
      }
      ,{
        path: '/addUser',
        name: 'addUser',
        component: () => import('@/views/publicmanage/addUser'),
        meta: { title: '添加用户', keepAlive:false, parentName:'addUser', shortName:'TJYH' },
      },
      {
        path: '/dataForm',
        name: 'dataForm',
        component: () => import('@/views/publicmanage/dataForm'),
        meta: { title: '数据报表', keepAlive:false, parentName:'dataForm', shortName:'SJBB' },
      },
    ]
  },

  {
    path: '/friend',
    component: Layout,
    meta: { title: '友邻圈管理', parentName: 'friendManage' },
    children: [
      {
        path: '/examine',
        name: 'examine',
        component: () => import('@/views/friend/examine'),
        meta: { title: '友领圈审核', keepAlive: false, parentName: 'examine', shortName: 'YLQSH' }
      }
    ]
  },

  {
    path: '/labelMNger',
    component: Layout,
    meta: { title: '标签管理', parentName: 'labelMNger' },
    children: [
      {
        path: '/public',
        name: 'public',
        component: () => import('@/views/labelMNger/public'),
        meta: { title: '公众标签管理', keepAlive: false, parentName: 'public', shortName: 'GZBQ' }
      },
      {
        path: '/tasktipes',
        name: 'tasktipes',
        component: () => import('@/views/labelMNger/tasktipes'),
        meta: { title: '任务标签管理', keepAlive: false, parentName: 'tasktipes', shortName: 'RWBQ' }
      }
    ]
  },

  {
    path: '/Pmall',
    component: Layout,
    meta: { title: '积分商城管理', parentName: 'Pmall' },
    children: [
      {
        path: '/banner',
        name: 'banner',
        component: () => import('@/views/Pmall/banner'),
        meta: { title: 'banner图管理', keepAlive: false, parentName: 'banner', shortName: 'BNGL' }
      },
       {
        path: '/brand',
        name: 'Brand',
        component: () => import('@/views/Pmall/brand'),
        meta: { title: '商城品牌', keepAlive: false, parentName: 'Brand', shortName: 'SCPP' }
      },       {
        path: '/preview',
        name: 'Preview',
        component: () => import('@/views/Pmall/preview'),
        meta: { title: '小程序效果预览', keepAlive: false, parentName: 'Preview', shortName: 'XCXGYL' }
      },
      {
        path: '/hot',
        name: 'hot',
        component: () => import('@/views/Pmall/hot'),
        meta: { title: '热门推荐管理', keepAlive: false, parentName: 'hot', shortName: 'RMTJ' }
      },
      {
        path: '/writeOff',
        name: 'writeOff',
        component: () => import('@/views/Pmall/writeOff'),
        meta: { title: '核销人管理', keepAlive: false, parentName: 'writeOff', shortName: 'HXR' }
      },
      {
        path: '/reportForm',
        name: 'reportForm',
        component: () => import('@/views/Pmall/reportForm'),
        meta: { title: '商城报表', keepAlive: false, parentName: 'reportForm', shortName: 'SCBB' }
      },
      {
        path: '/special',
        name: 'special',
        component: () => import('@/views/Pmall/special'),
        meta: { title: '特价兑换报表', keepAlive: false, parentName: 'special', shortName: 'TJDH' }
      }
    ]
  },

  {
    path: '/purchase',
    component: Layout,
    meta: { title: '采购商城管理', parentName: 'purchase' },
    children: [
      {
        path: '/PMalls',
        name: 'PMalls',
        component: () => import('@/views/purchase/PMall'),
        meta: { title: '采购商城管理', keepAlive: false, parentName: 'PMall', shortName: 'CGSC' }
      },
      {
        path: '/orders',
        name: 'orders',
        component: () => import('@/views/purchase/orders'),
        meta: { title: '采购订单管理', keepAlive: false, parentName: 'orders', shortName: 'CGDD' }
      },
      {
        path: '/purchased',
        name: 'purchased',
        component: () => import('@/views/purchase/purchased'),
        meta: { title: '已够商品管理', keepAlive: false, parentName: 'purchased', shortName: 'YGSP' }
      },
      {
        path: '/addProductss',
        name: 'addProductss',
        component: () => import('@/views/purchase/addProductss'),
        meta: {title: '编辑商品', keepAlive: false, parentName: 'addProductss', shortName: 'BJSP'}
      },
    ]
  },

  {
    path: '/Pinfomation',
    component: Layout,
    meta: { title: '公众信息配置', parentName: 'Pinfomation' },
    children: [
      {
        path: '/pallocation',
        name: 'pallocation',
        component: () => import('@/views/Pinfomation/pallocation'),
        meta: { title: '公众配置', keepAlive: false, parentName: 'pallocation', shortName: 'GZPZ' }
      }
      // {
      //   path: '/orders',
      //   name: 'orders',
      //   component: () => import('@/views/purchase/orders'),
      //   meta: { title: '采购订单管理', keepAlive:false,parentName:'orders', shortName:'CGDD' },
      // },
      // {
      //   path: '/purchased',
      //   name: 'purchased',
      //   component: () => import('@/views/purchase/purchased'),
      //   meta: { title: '已够商品管理', keepAlive:false,parentName:'purchased', shortName:'YGSP' },
      // },
    ]
  }
]
